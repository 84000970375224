import React from "react"
import logo from '../logo.svg'
import aaron from '../images/aaron.jpg'
import bryan from '../images/bryan.jpg'

function AboutWTAD(){
  return (
    <section className='about-wtad' id='about'>
      <div className='about-wtad-content'>
        <h2 className='about-wtad-title'>About WTAD</h2>
        <p className='about-wtad-description'>
            A couple of software engineers who have been in the industry for over 30 years combined. We have seen the good, the bad, and the ugly. 
            We aim to share our experiences, opinions and discuss the truths unheard from others in trenches.
        </p>
        <h2>Our Hosts</h2>
        <div className='about-wtad-hosts'>
          <div className='about-wtad-host'>
            <div className='about-wtad-host-img'>
              <img src={aaron} alt='Aaron Wallace' />
            </div>
            <div className='about-wtad-host-name'>Aaron Wallace</div>
            <div className='about-wtad-host-description'>Aaron is veteran of the software and web development industry with over 18 years of experience. 
                He has served in roles ranging from CTO to IC Engineer
            </div>
          </div>
          <div className='about-wtad-host'>
            <div className='about-wtad-host-img'>
              <img src={bryan} alt='WhatTheActualDev' />
            </div>
            <div className='about-wtad-host-name'>Bryan Foster</div>
            <div className='about-wtad-host-description'>Bryan is passionate about building solutions for today&rsquo;s problems with tomorrow&rsquo;s technologies. He is a Co-Founder of Salt Lake City Azure Group. Always rooting for the underdog and constantly cheering for the unexpected success in people.</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutWTAD