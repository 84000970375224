import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faApple, faYoutube } from '@fortawesome/free-brands-svg-icons'

function ListenNow(){
  return (
    <section className='listen-now' id='listen'>
      <div className='listen-now-content'>
        <h2 className='listen-now-title'>Listen Now</h2>
        <div className='listen-now-links'>
          <a href='https://podcasters.spotify.com/pod/show/whattheactualdev' className='listen-now-link'><FontAwesomeIcon icon={faSpotify} /><br /><span>Follow Us!</span></a>
          <a href='https://podcasts.apple.com/us/podcast/whattheactualdev/id1728135769' className='listen-now-link'><FontAwesomeIcon icon={faApple} /><br /><span>Subscribe!</span></a>
          <a href='https://www.youtube.com/@WhatTheActualDev/' className='listen-now-link'><FontAwesomeIcon icon={faYoutube} /><br /><span>Watch and Follow!</span></a>
        </div>
      </div>
    </section>
  )
}

export default ListenNow