import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  return (
    <footer>
      <div className='footer-content'>
        <div className='footer-social'>
          {/* <a href='#' className='footer-social-link'><FontAwesomeIcon icon={faFacebook} /></a> */}
        </div>
        <div className='footer-copy'>
            &copy; 2023 WhatTheActualDev
        </div>
      </div>
    </footer>
  )
}

export default Footer