import React from 'react'
import logo from './logo.svg'
import './App.scss'
import Menu from './components/menu'
import Hero from './components/hero'
import AboutWTAD from './components/about'
import ListenNow from './components/listen'
import Contact from './components/contact'
import Footer from './components/footer'

function App() {
  return (
    <div className='App'>
      <section className='header'>
        <div className='content'>
          <div className='logo'>
            <img src={logo} alt='WhatTheActualDev' />
          </div>
          <Menu />
        </div>
      </section>
      <Hero />
      <AboutWTAD />
      <ListenNow />
      <Contact />
      <Footer />
    </div>
  )
}

export default App
