import axios from "axios"

const postReq = (content:object, route:string) => {
  const fullUrl = 'https://fn-api-signup-endpoint.azurewebsites.net/api/PodcastSignup?code=HXmQR-7rz0dDipcF5f_4PFN1Ma34-HlhxnCEcgXoJ8xLAzFu5Hl4CQ=='
  return axios.post(fullUrl, content)
}

const api = {
  postReq
}

export default api
