import React from 'react'
import '../App.scss'

function Menu() {

  return (
    <div className='menu'>
      <a href='#home' className='menu-item'>Home</a>
      <a href='#about' className='menu-item'>About</a>
      <a href='#listen' className='menu-item'>Listen</a>
      <a href='#contact' className='menu-item'>Contact</a>
    </div>
  )
}

export default Menu