import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophoneLines } from '@fortawesome/pro-regular-svg-icons'

function Hero(){
  return (
    <section className='hero' id='home'>
      <div className='img-overlay'></div>
      <div className='hero-content'>
        <h1 className='hero-title'>No nonsense, No BullSh**</h1>
        <p className='hero-description'>Discussing the truths unheard from the software and web development industries.</p>
        <div className='btn'><a href='#listen'><FontAwesomeIcon className='btn-icon' icon={faMicrophoneLines} /> Listen Now</a></div>
      </div>
    </section>
  )
}

export default Hero