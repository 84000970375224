import React from "react"
import api from "../api-interface/index"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons'

function Contact(){
  const validateField = (emailField:string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(emailField) || emailField === '') {
      alert('Please enter a valid email address') 
      return false
    }
    
    return true
  }

  const formHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(e.currentTarget.fullName.value === '' || e.currentTarget.email.value === '' || e.currentTarget.message.value === '') {
      alert('Please fill out all fields')
      return
    }

    if(validateField(e.currentTarget.email.value)) {
      const FormValues = {
        name: e.currentTarget.fullName.value,
        email: e.currentTarget.email.value,
        message: e.currentTarget.message.value
      }

      // post to api interface
      api.postReq(FormValues, 'contactFormSubmit')
        .then((res) => {
          if(res.status === 200) {
            const form = document.querySelector('form')
            form?.reset()
            alert('Message sent successfully!')
          }
        }).catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <section className='contact' id='contact'>
      <div className='contact-content'>
        <h2 className='contact-title'>Contact</h2>
        <p className='contact-description'>
          Want to work with us or have any other business inquires?
        </p>
        <div className='contact-form'>
          <form onSubmit={formHandler}>
            <div className='contact-form-row'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='fullName' />
            </div>
            <div className='contact-form-row'>
              <label htmlFor='email'>Email</label>
              <input type='text' id='email' name='email' />
            </div>
            <div className='contact-form-row'>
              <label htmlFor='message'>Message</label>
              <textarea id='message' name='message' />
            </div>
            <div className='contact-form-row'>
              <button type='submit' className='btn-large'><FontAwesomeIcon icon={faPaperPlaneTop} /> Submit it... Now, Right Now.</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}
export default Contact